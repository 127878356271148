import React from "react"

import { styled } from "@material-ui/styles"

const styles = {
  "& > .content": {
    position: "relative",
    // originally set to `display: none` in global.css
    // here we take advantage of `styled` being only in effect with javascript (at least for the version used) to show the hide button again
    // this way clients without javascript do not have to see very out-of-placed the `.mask` and `href="#hide-answer"` buttons
    '& > a[href="#hide-answer"]': {
      display: "unset",
    },
    "& > .mask": {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: "#000",
      zIndex: 3,
      // same as the hide answer button. setting `display` value shows the mask again
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        background: "#333",
      },
    },
  },
}

class Answer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showAnswer: !!props.show }
  }
  showAnswer(e) {
    e.preventDefault()
    this.setState({
      showAnswer: true,
    })
  }
  hideAnswer(e) {
    e.preventDefault()
    this.setState({
      showAnswer: false,
    })
  }
  render() {
    return (
      <div {...this.props}>
        <div className="content">
          {this.state.showAnswer || (
            <a
              href="#show-answer"
              className="mask"
              onClick={this.showAnswer.bind(this)}
            >
              Click to show answer
            </a>
          )}
          <a href="#hide-answer" onClick={this.hideAnswer.bind(this)}>
            (hide)
          </a>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default styled(Answer)(styles)
