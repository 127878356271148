import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

Wordpress2016.overrideThemeStyles = () => {
  return {
    a: {
      color: "var(--textLink)",
    },
    // gatsby-remark-autolink-headers - don't underline when hidden
    "a.anchor": {
      boxShadow: "none",
    },
    // gatsby-remark-autolink-headers - use theme colours for the link icon
    'a.anchor svg[aria-hidden="true"]': {
      stroke: "var(--textLink)",
    },
    hr: {
      background: "var(--hr)",
    },
    // used opacity to adapt blockquote to a "lighter" text both in dark and light mode
    blockquote: {
      color: "var(--textNormal)",
      opacity: 0.7,
    },
    // text looks "lighter" when in dark mode for the same opacity. specifically increased opacity to unify percepted "lightness" in dark vs light mode
    ".dark blockquote": {
      opacity: 0.8,
    },
  }
}

delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
